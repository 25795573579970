import { Route, Routes } from 'react-router-dom'

import { Login } from './components/Login'
import { AuthLayout } from './AuthLayout'

import { FC} from 'react'

const AuthPage: FC = () => {


  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        {/* <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} /> */}
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}
export { AuthPage }
