import { FC, useEffect, useState } from "react"
import axios from "axios"
import { URL } from '../../../redux/common/url'

const VendorWise: FC = () => {
  const [vendor, setVendor] = useState([])
  const [vehical, setVehical] = useState([])

  const user = JSON.parse(localStorage.getItem('access_admin_token')!).data

  const all_vehicles = async () => {
    try {
      const res = await axios.get(`${URL.API_BASE_URL}${URL.all_vehicles}`)
      console.log(res?.data?.results);
      if (res?.data?.results) {
        setVehical(res?.data?.results)
      }
    } catch (error) {
      console.log(error);

    }
  }




  const fetchgetAllVendor = async () => {
    try {
      const res = await axios.get(`${URL.API_BASE_URL}${URL.getVendorList}?circle_id=${user?.circle_id}`)
      console.log(res?.data?.results);
      if (res?.data?.results) {
        setVendor(res?.data?.results)
      }

    } catch (error) {
      console.log(error);

    }

  }

  useEffect(() => {
    fetchgetAllVendor()
    all_vehicles()
  }, [])


  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3 className="text-gray-600" >Trip Report Vendor Wise</h3>
        <p><span className="text-primary">Home</span> / Trip Report Vendor Wise</p>
      </div>
      <div className="bg-white p-3 pt-6 shadow m-0 rounded-2xl">

        <div className="d-flex px-5 justify-content-between">
          <div className="me-3" style={{ 'position': 'relative' }}>
            <label><b>Vendor</b></label> <br />
            <select className="form-control" name="" id="" style={{ 'width': '200px' }}>
              <option value="">Select</option>
              {
                vendor.length > 0 && vendor.map((item: any, index: any) => (
                  <option value={item?.id} key={index}>{item?.name}</option>
                ))
              }
            </select>


          </div>

          <div className="me-3" style={{ 'position': 'relative' }}>
            <label><b>Vehicle</b></label> <br />
            <select className="form-control" name="" id="" style={{ 'width': '200px' }}>
              <option value="">Select</option>
              {
                vehical.length > 0 && vehical.map((item: any, index: any) => (
                  <option value={item?.id} key={index}>{item?.name}</option>
                ))
              }
            </select>

          </div>
          <div className="me-3">
            <label><b>Date From:</b></label> <br />
            <input className="form-control" type="date" />
          </div>
          <div className="me-3">
            <label><b>Date To:</b></label> <br />
            <input className="form-control" type="date" />
          </div>
          <div className="  mt-8" style={{ 'backgroundColor': '#52A841', 'color': 'white', 'height': '30px', 'width': '250px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>View</div>
        </div>


        <div className="d-flex px-5 justify-content-betq gap-4 align-items-center mt-4">
          <div className="d-flex gap-1">
            <div className=" p-2" style={{ 'border': '1px solid #000', 'backgroundColor': '#ADAFAD' }}>Copy</div>
            <div className=" p-2" style={{ 'border': '1px solid #000', 'backgroundColor': '#ADAFAD' }}>CSV</div>
            <div className=" p-2" style={{ 'border': '1px solid #000', 'backgroundColor': '#ADAFAD' }}>PDF</div>
            <div className=" p-2" style={{ 'border': '1px solid #000', 'backgroundColor': '#ADAFAD' }}>EXCEL</div>
          </div>

          <div>

            <input type="search" placeholder="Search wise Name" className="form-control" />
          </div>

        </div>


        <div className="mt-9">
          <table className="table" style={{ 'fontSize': '12px', 'fontWeight': '300' }}>
            <thead className="table-light ">
              <tr>
                <th>S.NO.</th>
                <th>Job Id</th>
                <th>Pickup location</th>
                <th>Drop location</th>
                <th>Pickup Date / time</th>
                <th>End Date / time</th>
                <th>Trip Duration</th>
                <th>Name</th>
                <th>Number</th>
                <th>Tanker Number</th>
                <th>Distance</th>
                <th>Amount</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ 'maxHeight': '60vh', 'overflow': 'auto' }} >
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody >
            <tr>
              <td colSpan={6}></td>
              <td><b>Date</b></td>
              <td> 31-05-2024 to 31-05-2024</td>
              <td colSpan={2}></td>
              <td><b>Total</b></td>
              <td colSpan={2} className="text-center fs-5">908</td>
              <td ></td>

            </tr>
            <tfoot>
              <tr>
                <td colSpan={13} style={{ 'textAlign': 'center' }}>
                  This is System Generated Report it does not require any signature or stamp.
                </td>
              </tr>
            </tfoot>
          </table>
          <p>Showing 1 to 201 of 201 entries</p>
        </div>



      </div>
    </div>
  )
}

export default VendorWise