import { useState } from "react"


const VehicalWise = () => {
  const [vendor, setVendor] = useState([])
  const [vehical, setVehical] = useState([])

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3 className="text-gray-600" >Trip Report Vehicle Wise</h3>
        <p><span className="text-primary">Home</span> / Trip Report Vehicle Wise</p>
      </div>
      <div className="bg-white p-3 pt-6 shadow m-0 rounded-2xl">

        <div className="d-flex px-5 justify-content-between">
          <div className="me-3" style={{ 'position': 'relative' }}>
            <label><b>Vendor</b></label> <br />
            <input className="form-control" type="search" />
            {
              vendor.length > 0 && (
                <div style={{
                  'position': 'absolute', 'width': '100%', 'backgroundColor': 'white',
                  'boxShadow': '0 0 0 1px rgb(0 0 0 / 20%), 0 4px 11px rgb(0 0 0 / 10%)', 'display': 'flex', 'flexDirection': 'column', 'gap': '10px'
                }}>
                  <div >
                    <div className="select" >"Hello World"</div>
                    <div className="select" >"Hello World"</div>
                    <div className="select" >"Hello World"</div>
                    <div className="select" >"Hello World"</div>
                  </div>
                </div>
              )
            }
          </div>

          <div className="me-3" style={{ 'position': 'relative' }}>
            <label><b>Vehicle</b></label> <br />
            <input className="form-control" type="search" />
            {
              vehical.length > 0 && (
                <div style={{
                  'position': 'absolute', 'width': '100%', 'backgroundColor': 'white',
                  'boxShadow': '0 0 0 1px rgb(0 0 0 / 20%), 0 4px 11px rgb(0 0 0 / 10%)', 'display': 'flex', 'flexDirection': 'column', 'gap': '10px'
                }}>
                  <div >
                    <div className="select" >"Hello World"</div>
                    <div className="select" >"Hello World"</div>
                    <div className="select" >"Hello World"</div>
                    <div className="select" >"Hello World"</div>
                  </div>
                </div>
              )
            }
          </div>
          <div className="me-3">
            <label><b>Date From:</b></label> <br />
            <input className="form-control" type="date" />
          </div>
          <div className="me-3">
            <label><b>Date To:</b></label> <br />
            <input className="form-control" type="date" />
          </div>
          <div className="  mt-8" style={{ 'backgroundColor': '#52A841', 'color': 'white', 'height': '30px', 'width': '250px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>View</div>
        </div>

        <div className="d-flex px-5 justify-content-end gap-4 align-items-center">
          <div className="d-flex content-center justify-content-center gap-3 ">
            <input type="checkbox" id="select" />  <label htmlFor="select"><b>check / uncheck all</b></label>
          </div>

          <div className="  mt-8" style={{ 'backgroundColor': '#ADAFAD', 'color': 'white', 'height': '30px', 'width': '250px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
            Approve Selected
          </div>


          <div className="  mt-8" style={{ 'backgroundColor': '#E21965', 'color': 'white', 'height': '30px', 'width': '250px', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }}>
            Disapprove Selected
          </div>
        </div>

        <div className="d-flex px-5 justify-content-betq gap-4 align-items-center">
          <div className="d-flex gap-1">
            <div className=" p-2" style={{ 'border': '1px solid #000', 'backgroundColor': '#ADAFAD' }}>Copy</div>
            <div className=" p-2" style={{ 'border': '1px solid #000', 'backgroundColor': '#ADAFAD' }}>CSV</div>
            <div className=" p-2" style={{ 'border': '1px solid #000', 'backgroundColor': '#ADAFAD' }}>PDF</div>
            <div className=" p-2" style={{ 'border': '1px solid #000', 'backgroundColor': '#ADAFAD' }}>EXCEL</div>
          </div>

          <div>

            <input type="search" placeholder="Search wise Name" className="form-control" />
          </div>

        </div>


        <div className="mt-9">
          <table className="table" style={{ 'fontSize': '12px', 'fontWeight': '300' }}>
            <thead className="table-light ">
              <tr>
                <th>S.NO.</th>
                <th>Job Id</th>
                <th>Pickup location</th>
                <th>Drop location</th>
                <th>Pickup Date / time</th>
                <th>End Date / time</th>
                <th>Trip Duration</th>
                <th>Name</th>
                <th>Number</th>
                <th>Tanker Number</th>
                <th>Distance</th>
                <th>Amount</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ 'maxHeight': '60vh', 'overflow': 'auto' }} >
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody >
            <tr>
              <td colSpan={6}></td>
              <td><b>Date</b></td>
              <td> 31-05-2024 to 31-05-2024</td>
              <td colSpan={2}></td>
              <td><b>Total</b></td>
              <td colSpan={2} className="text-center fs-5">908</td>
              <td ></td>

            </tr>
            <tr>
              <td colSpan={13} style={{ 'textAlign': 'center' }}>
                This is System Generated Report it does not require any signature or stamp.
              </td>
            </tr>
            <tfoot>

            </tfoot>
          </table>
          <p>Showing 1 to 201 of 201 entries</p>
        </div>



      </div>
    </div>
  )
}

export default VehicalWise