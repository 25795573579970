import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { adminAction } from '../../../redux/common/action'



function TestPage() {
  const dispatch = useDispatch()

  const get_dashboard: any = useSelector((state: any) =>
    state.admin.get_dashboard ? state.admin.get_dashboard : {}
  )



  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    dispatch(adminAction.dashboard(1))
    // dispatch(adminAction.getRoleList(''))
    // dispatch(adminAction.getgetAllCircles(''))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => { }
  }, [])
  // dashboard
  return (
    <div>
      <h1>DashBoard</h1>
      <div className='row  align-items-center min-h-screen '>


        {/* <div className='col-3'>
          <h3>name</h3>

        </div> */}
        {/* <div className='col-3'>
          <p>{usData?.data?.user?.name}</p>

        </div> */}
        <div className='col-md-3 col-6'>
          <Link to={'/booking-filter/Pending'} className='  text-center nevBoxco' style={{ backgroundColor: "#f1d9f7" }}>
            <p className='text-drak'>{get_dashboard?.pending ? get_dashboard?.pending : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Waiting For Driver</h6>
            <i className="fa-solid fa-hourglass-start fs-3 text-primary"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/booking-filter/Assigned'} className='  text-center nevBoxco' style={{ backgroundColor: "#c6ffa1" }}>
            <p className='text-drak'>{get_dashboard?.driver_assign ? get_dashboard?.driver_assign : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Driver Assigned</h6>
            <i className="fa-regular fa-id-card fs-2 text-primary"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/booking-filter/Accepted'} className=' text-center nevBoxco' style={{ backgroundColor: "#ffe3ef" }}>
            <p className='text-drak'>{get_dashboard?.accepted ? get_dashboard?.accepted : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Driver Accepted</h6>
            <i className="fa-solid fa-clipboard-check fs-2 text-primary"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/booking-filter/Arrived-To-FillingStation'} className='  text-center nevBoxco' style={{ backgroundColor: "#cdcdf7" }}>
            <p className='text-drak'>{get_dashboard?.arrived_hydrent ? get_dashboard?.arrived_hydrent : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Reached Hydrent</h6>
            <i className="fa-solid fa-flag-checkered fs-2 text-primary"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/booking-filter/Going-To-Destination'} className='  text-center nevBoxco' style={{ backgroundColor: "#f1d9f7" }}>
            <p className='text-drak'>{get_dashboard?.on_going ? get_dashboard?.on_going : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} On Going</h6>
            <i className="fa-solid fa-person-running fs-2 text-primary"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/booking-filter/Filling-Started'} className='  text-center nevBoxco' style={{ backgroundColor: "#c6ffa1" }}>
            <p className='text-drak'>{get_dashboard?.water_supply_started ? get_dashboard?.water_supply_started : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Water Supply Start</h6>
            <i className="fa-solid fa-battery-half fs-2 text-primary"></i>
          </Link>
        </div>
        {/* <div className='col-md-3 col-6'>
          <Link to={'/rate'} className=' text-center nevBoxco' style={{ backgroundColor: "#ffe3ef" }}>
            <p className='text-drak'>{get_dashboard?.driver_assign?get_dashboard?.driver_assign :0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Water Supply Stop</h6>

          </Link>
        </div> */}
        <div className='col-md-3 col-6'>
          <Link to={'/booking-filter/Going-To-Destination'} className='  text-center nevBoxco' style={{ backgroundColor: "#ffe3ef" }}>
            <p className='text-drak'>{get_dashboard?.otp_pending ? get_dashboard?.otp_pending : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Customer Otp Pending</h6>
            <i className="fa-solid fa-spinner fs-2 text-primary"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/booking-filter/Completed'} className='  text-center nevBoxco' style={{ backgroundColor: "#cdcdf7" }}>
            <p className='text-drak'>{get_dashboard?.completed ? get_dashboard?.completed : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Completed </h6>
            <i className="fa-solid fa-check-double fs-2 text-primary"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/cancel-booking'} className='  text-center nevBoxco' style={{ backgroundColor: "#f1d9f7" }}>
            <p className='text-drak'>{get_dashboard?.cancelled ? get_dashboard?.cancelled : 0}</p>
            <h6 className='text-drak'>Cancelled</h6>
            <i className="fa-solid fa-ban  fs-2 text-danger"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/vehicles'} className=' text-center nevBoxco' style={{ backgroundColor: "#c6ffa1" }}>
            <p className='text-drak'>{get_dashboard?.total_tanker ? get_dashboard?.total_tanker : 0}</p>
            <h6 className='text-drak'>Total Tanker</h6>
            <i className="fa-solid fa-truck-droplet fs-2 text-primary"></i>
          </Link>
        </div>
        <div className='col-md-3 col-6'>
          <Link to={'/all-booking'} className='  text-center nevBoxco' style={{ backgroundColor: "#ffe3ef" }}>
            <p className='text-drak'>{get_dashboard?.total_booking ? get_dashboard?.total_booking : 0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Total Booking</h6>
            <i className="fa-solid fa-list-check fs-2 text-primary"></i>
          </Link>
        </div>
        {/* <div className='col-md-3 col-6'>
          <Link to={'/all-booking'} className='  text-center nevBoxco' style={{ backgroundColor: "#cdcdf7" }}>
            <p className='text-drak'>{get_dashboard?.total_booking?get_dashboard?.total_booking :0}</p>
            <h6 className='text-drak'> {moment(new Date()).format('ddd MMM DD')} Total Booking</h6>
          </Link>
        </div> */}
      </div>
    </div>
  )
}

export default TestPage
